<template>
  <div class="gateway homepage">
    <!-- header 头部 -->
    <gateway-header :bgAttr="bgAttr" />
    <!-- header 头部 end -->

    <div class="gateway-banner">
      <video @click="dialogVisible = true" id="banner-video"
        :src="((bannerData.url && bannerData.url.replace('http://', '//'))  || '//agri-cloud.oss-cn-shenzhen.aliyuncs.com/zhanyu/homepage-banner-new.mp4')"
        muted autoplay @ended="gotoNext" />
      <!-- <div class="play-model">
        <img src="./modules/playVideo.png" />
      </div> -->
    </div>
    <!-- 数字渔业园区 -->
    <div class="fishery-park gateway-module" id="fishery-park">
      <div class="module-title">数字渔业园区</div>
      <div class="module-content">
        <div class="column-box">
          <div class="column-box">
            <div class="ls-item">
              <img src="../../assets/img/gateway/homepage/icon-fishery-park-01.png" alt="" />
              <span>{{ fisheryParkData.annualOutputValueTotal }}</span><i>年总产值/亿元</i>
            </div>
            <div class="ls-item">
              <img src="../../assets/img/gateway/homepage/icon-fishery-park-02.png" alt="" />
              <span>{{ fisheryParkData.foddersFeedOutputValue }}</span><i>饲料年产值/亿元</i>
            </div>
            <div class="ls-item">
              <img src="../../assets/img/gateway/homepage/bg-center.png" alt="" />
            </div>
            <div class="ls-item">
              <img src="../../assets/img/gateway/homepage/icon-fishery-park-04.png" alt="" />
              <span>{{ fisheryParkData.foddersProcessingOutputValue }}</span><i>加工年总产值/亿元</i>
            </div>
            <div class="ls-item">
              <img src="../../assets/img/gateway/homepage/icon-fishery-park-05.png" alt="" />
              <span>{{ fisheryParkData.logisticsInputOutMoney }}</span><i>进出口总额/亿元</i>
            </div>
          </div>
        </div>
        <div class="row-box">
          <div class="ls-item">
            <img src="../../assets/img/gateway/homepage/icon-fishery-park-06.png" alt="" />
            <span>{{ fisheryParkData.companyTotal }}</span><i>企业主体/家</i>
          </div>
          <div class="ls-item">
            <img src="../../assets/img/gateway/homepage/icon-fishery-park-07.png" alt="" />
            <span>{{ fisheryParkData.farmersNumber }}</span><i>联农带户/户</i>
          </div>
          <div class="ls-item">
            <img src="../../assets/img/gateway/homepage/icon-fishery-park-08.png" alt="" />
            <span>{{ fisheryParkData.breedingArea }}</span><i>养殖面积/亩</i>
          </div>
          <div class="ls-item">
            <img src="../../assets/img/gateway/homepage/icon-fishery-park-09.png" alt="" />
            <span>{{ fisheryParkData.cultureCage }}</span><i>冷库数量/吨</i>
          </div>
        </div>
      </div>
    </div>

    <!-- 濠江生态链 -->
    <div class="ecological-chain gateway-module">
      <!-- @mouseleave="handelMoveLeaveEcological" -->
      <div class="module-content">
        <div class="content-left">
          <div class="title">濠江风情</div>
          <ul>
            <li v-for="(ele, idx) in chainls" :key="idx" :class="{ active: idx === chainAct }"
              @mouseenter="handelMoveEnterEcological(ele.type, idx)" @click="checkMenu(ele, idx, 'ecological')">
              {{ ele.txt }}
            </li>
          </ul>
        </div>
        <div class="content-right" :style="{
          background: 'center center url(' + ecologicalBgUrl + ') no-repeat',
        }">
          <ul v-if="ecologicalData[ecologicalType]">
            <li v-if="ecologicalData[ecologicalType].content1" :title="ecologicalData[ecologicalType].content1">
              <i>◆&nbsp;</i>{{ ecologicalData[ecologicalType].content1 }}
            </li>
            <li v-if="ecologicalData[ecologicalType].content2" :title="ecologicalData[ecologicalType].content1">
              <i>◆&nbsp;</i>{{ ecologicalData[ecologicalType].content2 }}
            </li>
            <li v-if="ecologicalData[ecologicalType].content3" :title="ecologicalData[ecologicalType].content1">
              <i>◆&nbsp;</i>{{ ecologicalData[ecologicalType].content3 }}
            </li>
            <li v-if="ecologicalData[ecologicalType].content4" :title="ecologicalData[ecologicalType].content1">
              <i>◆&nbsp;</i>{{ ecologicalData[ecologicalType].content4 }}
            </li>
            <li v-if="ecologicalData[ecologicalType].content5" :title="ecologicalData[ecologicalType].content1">
              <i>◆&nbsp;</i>{{ ecologicalData[ecologicalType].content5 }}
            </li>
            <li v-if="ecologicalData[ecologicalType].content6" :title="ecologicalData[ecologicalType].content1">
              <i>◆&nbsp;</i>{{ ecologicalData[ecologicalType].content6 }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 产业园入驻企业 -->
    <div class="settled-enterprise gateway-module">
      <div class="module-title">产业园入驻企业</div>
      <div class="module-content">
        <div class="company-ls">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide v-for="(ele, i) in companyLogoData" :key="i">
              <img :class="i === moveInImage ? 'image-magnify' : ''" @mouseenter="moveInImage = i"
                @mouseleave="moveInImage = ''" :src="ele" />
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </div>

    <!-- 新闻中心 -->
    <div class="news-center gateway-module">
      <div class="module-title">新闻中心</div>
      <div class="module-content">
        <div class="news-nav-box">
          <div class="ls-item" v-for="(ele, i) in newsCenterData" :key="ele.id">
            <div class="img-box">
              <img :src="ele.urlKey" />
              <div class="new-detail" @click="seeNewDetail(i)">
                新闻详情
                <img src="../../assets/img/gateway/homepage/img-turn-right.png" alt="" />
              </div>
            </div>
            <div class="item-center">
              <h5>
                {{
                transitionTime(ele.createTime, {
                type: 'cutOut',
                target: 'month',
                })
                }}
              </h5>
              <span>{{
              transitionTime(ele.createTime, {
              type: 'replace',
              target: '年月',
              })
              }}</span>
              <i style="margin-top: 5px">时间——</i>
            </div>
            <div class="item-left" @click="seeNewDetail(i)">
              <h5>{{ ele.title }}</h5>
              <article>
                <div v-html="ele.summary" />
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>

    <gateway-footer :style="'background: #ffffff; color: #1b1e2a;'" />
    <ToTopperComponent />

    <el-dialog title="弹窗视频" :visible.sync="dialogVisible" width="50%" class="preview-video-dialog">
      <video :src="
        dialogVisible
          ? (bannerData.url && bannerData.url.replace('http://', '//')) ||
            '//agri-cloud.oss-cn-shenzhen.aliyuncs.com/zhanyu/homepage-banner-new.mp4'
          : ''
      " autoplay muted controls />
    </el-dialog>
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'

import GatewayHeader from '../../components/gateway-header.vue'
import GatewayFooter from '../../components/gateway-footer.vue'
import ToTopperComponent from '../../components/to-topper-component.vue'
import {
  getArticle,
  getEcologicalChain,
  getLogo,
  getParkInformationByNew,
  getVideo,
} from '@/views/gateway/modules/home-page-api'
export default {
  name: 'homepage',
  components: {
    swiper,
    swiperSlide,
    GatewayFooter,
    GatewayHeader,
    ToTopperComponent,
  },
  data() {
    return {
      dialogVisible: false,
      bgAttr: 'rgba(54, 89, 241,0.6)',
      //首页视频
      bannerData: {},
      // 数字渔业园区
      fisheryParkData: {},
      // 新闻中心
      newsCenterData: {},
      // 濠江生态链
      chainls: [
        { type: '1', txt: '宜业' },
        { type: '2', txt: '宜企' },
        { type: '3', txt: '宜居' },
        { type: '4', txt: '宜游' },
      ],
      ecologicalData: {},
      ecologicalType: 1,
      ecologicalBgUrl: '',
      showList: false,

      chainAct: 0,
      // 暂存值属性
      rootChainAct: '',
      rootEcologicalType: 1,

      // 产业园入驻企业
      companyLogoData: {},
      companyLogoType: '1',
      moveInImage: '',
      swiperOption: {
        slidesPerView: 5,
        slidesPerColumn: 2,
        spaceBetween: 25,
        slidesPerGroup: 1,
        slidesPerColumnFill: 'row',
        pagination: {
          el: '.swiper-pagination',
          bulletActiveClass: 'bullet-active',
          clickable: true,
        },
      },
      enterpriseAct: 0,
      // 暂存值属性
      rootEnterpriseAct: 1,
      rootCompanyLogoType: 1,
    }
  },
  computed: {},
  // 数据监听
  watch: {
    //
  },
  created() {
    // 入驻企业logo
    this.handelGetLogo('1')

    // 首页视频
    this.handelGetVideo()
    // 新闻中心
    this.handelGetArticle('1')
    // 濠江风情
    this.handelGetEcologicalChain('1')
    this.handelGetEcologicalChain('2')
    this.handelGetEcologicalChain('3')
    this.handelGetEcologicalChain('4')

    this.handelGetParkInformationByNew()
  },
  methods: {
    gotoNext() {
      // const video = document.querySelector('#banner-video')
      // video.loop = true
      // video.play()

      const appId = document.querySelector('#app')
      const anchor = document.querySelector('#fishery-park')
      // appId.scrollTop = anchor.offsetTop

      let top = appId.scrollTop
      let count = 1

      const timeTop = setInterval(() => {
        appId.scrollTop = top += 2 * count
        count++

        if (top >= anchor.offsetTop) {
          clearInterval(timeTop) //清除定时器
        }
      }, 14)
    },
    checkMenu(data, index, type) {
      if (type === 'ecological') {
        this.rootChainAct = index
        this.rootEcologicalType = data.type
      } else {
        this.rootEnterpriseAct = index
        this.rootCompanyLogoType = data.type
      }
    },
    /**
     * @Author: chenjie
     * @Date: 2021-10-26 15:21:46
     * @Descripttion: 日期转化-私有
     * @params {*}
     * @param {*} date
     * @param {*} options
     */
    transitionTime(date, options) {
      let resultTime = ''
      if (options.type === 'cutOut') {
        if (options.target === 'month') {
          resultTime = date.slice(5, 7)
        }
      } else if (options.type === 'replace') {
        let year
        let mounth
        if (options.target === '年月') {
          year = date.slice(0, 4) + '年'
          mounth = date.slice(5, 7) + '月'
        }
        resultTime = year + mounth
      }
      return resultTime
    },
    /**
     * @Author: chenjie
     * @Date: 2021-10-26 15:26:48
     * @Descripttion: 查看新闻详情
     * @params {*}
     * @param {*} id
     */
    seeNewDetail(id) {
      this.$router.push(`/newsCenter?type=0&isDetail=1&id=${id}`)
    },
    /**
     * @Author: chenjie
     * @Date: 2021-10-25 14:02:22
     * @Descripttion: 湛鱼生态链
     * @params {*}
     * @param {*} type
     * @param {*} idx
     */
    handelMoveEnterEcological(type, idx) {
      this.chainAct = idx
      this.ecologicalType = type
      this.ecologicalBgUrl = this.ecologicalData[this.ecologicalType].url
    },
    handelMoveLeaveEcological() {
      this.chainAct = this.rootChainAct
      this.ecologicalType = this.rootEcologicalType
      this.ecologicalBgUrl = this.ecologicalData[this.rootEcologicalType].url
    },
    /**
     * @Author: chenjie
     * @Date: 2021-10-25 14:02:22
     * @Descripttion: 湛鱼生态链
     * @params {*}
     * @param {*} type
     * @param {*} idx
     */
    handelMoveEnterEnterprise(type, idx) {
      this.enterpriseAct = idx
      this.companyLogoType = type
    },
    handelMoveLeaveEnterprise() {
      this.enterpriseAct = this.rootEnterpriseAct
      this.companyLogoType = this.rootCompanyLogoType
    },
    /**
     * @Author: chenjie
     * @Date: 2021-10-25 10:54:57
     * @Descripttion: 获取首页信息门户的新闻
     * @params {*}
     * @param {*} params
     */
    handelGetArticle(type) {
      getArticle(type).then((res) => {
        this.newsCenterData = res.data
      })
    },
    /**
     * @Author: chenjie
     * @Date: 2021-10-25 10:55:08
     * @Descripttion: 获取首页信息门户湛鱼生态链
     * @params {*}
     * @param {*} params
     */
    handelGetEcologicalChain(type) {
      getEcologicalChain(type).then((res) => {
        // 存储多种类型的数据
        this.ecologicalData[type] = res.data
        // 默认背景
        this.ecologicalBgUrl = this.ecologicalData[1]?.url
      })
    },
    /**
     * @Author: chenjie
     * @Date: 2021-10-25 10:55:20
     * @Descripttion: 获取首页信息门户的入驻企业Logo
     * @params {*}
     * @param {*} params
     */
    handelGetLogo(type) {
      getLogo(type).then((res) => {
        this.companyLogoData = res.data
        // this.companyLogoData[type] = res.data
        // // 临时需求
        // let temparr = this.companyLogoData
        // console.log(temparr,'1');
        // temparr.length = 6
        // console.log(temparr,'2');
        // temparr = Array.from(temparr)
        // console.log(temparr,'3');
        // let isZ = temparr.slice(1).map((ele, idx) => {
        //   if (ele) {
        //     temparr = [...temparr, ...ele]
        //     return 1
        //   }
        //   return 0
        // })
        // console.log(temparr,'4');
        // !isZ.includes(0) &&
        //   isZ.map((ele, idx) => {
        //     this.companyLogoData[idx + 1] = temparr.slice(6)
        //   })
      })
    },
    /**
     * @Author: chenjie
     * @Date: 2021-10-25 10:55:28
     * @Descripttion: 获取首页信息门户数字渔业园区
     * @params {*}
     * @param {*} params
     */
    handelGetParkInformationByNew() {
      getParkInformationByNew().then((res) => {
        this.fisheryParkData = res.data
      })
    },
    /**
     * @Author: chenjie
     * @Date: 2021-10-25 10:55:39
     * @Descripttion: 获取首页信息视频
     * @params {*}
     * @param {*} params
     */
    handelGetVideo(params) {
      getVideo(params).then((res) => {
        this.bannerData = res.data
      })
    },
  },
  beforeMount() {
    // 模板编译/挂载之前
  },
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
}
</script>
<style lang="less" scoped>
@import './homepage.less';
</style>
