/*
 * @Descripttion:
 * @Author: chenjie
 * @Date: 2021-10-25 10:22:05
 * @LastEditors: zcy
 * @LastEditTime: 2022-09-06 17:04:27
 */

import axios from '@/axios'

/**
 * @Author: chenjie
 * @Date: 2021-10-25 10:29:02
 * @Descripttion: 获取首页信息门户的新闻
 * @params {*}
 * @param {*} type
 */
export function getArticle(type){
  return  axios({
    url:'/haojiang-portal/frontInformation/getArticle/' + type,
    method:'get'
  })
}
/**
 * @Author: chenjie
 * @Date: 2021-10-25 10:29:02
 * @Descripttion: 获取首页信息门户湛鱼生态链
 * @params {*}
 * @param {*} type
 */
export function getEcologicalChain(chain){
  return  axios({
    url:'/haojiang-portal/frontInformation/getEcologicalChain/' + chain,
    method:'get'
  })
}
/**
 * @Author: chenjie
 * @Date: 2021-10-25 10:29:02
 * @Descripttion: 获取首页信息门户的入驻企业Logo
 * @params {*}
 * @param {*} type
 */
export function getLogo(type){
  return  axios({
    url:'/haojiang-portal/frontInformation/queryCompanyLogoList',
    method:'get'
  })
}
/**
 * @Author: chenjie
 * @Date: 2021-10-25 10:29:02
 * @Descripttion: 获取首页信息门户数字渔业园区
 * @params {*}
 * @param {*} type
 */
export function getParkInformationByNew(type){
  return  axios({
    url:'/haojiang-portal/frontInformation/getParkInformationByNew',
    method:'get'
  })
}
/**
 * @Author: chenjie
 * @Date: 2021-10-25 10:29:02
 * @Descripttion: 获取首页信息门户图片
 * @params {*}
 * @param {*} type
 */
export function getVideo(type){
  return  axios({
    url:'/haojiang-portal/frontInformation/getVideo',
    method:'get'
  })
}
